<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14 18h2.857l4.394 11.579h9.892L34 22.342h-8.571"
    />
    <path
      :fill="color"
      d="M25.579 35.895a2.106 2.106 0 1 1-4.212-.002 2.106 2.106 0 0 1 4.212.002M31.895 35.895a2.106 2.106 0 1 1-4.212-.002 2.106 2.106 0 0 1 4.212.002"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
